import { graphql } from 'gatsby';
import React from 'react';
import { Container, Row } from 'react-bootstrap';

import ContentCol from '../components/contentCol/contentCol';
import Layout from '../components/layout/layout';
import NewsletterBanner from '../components/newsletterBanner/newsletterBanner';
import SeoPress from '../components/seoPress/seoPress';
import Wysiwyg from '../components/wysiwyg/wysiwyg';

const AboutPage = ({ data }) => {
  return (
    <Layout navbarOnWhite={true}>
      <SeoPress
        seo={data.wpSitePage.seo}
        title={data.wpSitePage.title}
        uri={`${data.wpSitePage.slug}`}
      />

      <main>
        <Container className="pt-7">
          <Row className="justify-content-center">
            <ContentCol>
              <Wysiwyg
                html={data.wpSitePage.pageSiteAbout.content}
                className="text-center"
              />
            </ContentCol>
          </Row>
        </Container>

        <hr className="my-5 w-50" />

        <section className="pt-4 pt-md-5 pb-6 pb-md-7">
          <NewsletterBanner />
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpSitePage(databaseId: { eq: 29 }) {
      title
      slug
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      pageSiteAbout {
        content
      }
    }
    wp {
      sharedSiteContent {
        siteNewsletterSignup {
          headline
        }
      }
    }
  }
`;

export default AboutPage;
